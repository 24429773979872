import React from 'react';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';
import moment from 'moment';
import { linkResolver } from '../utils/linkResolver';

import ContentBody from '../components/ContentBody';
import NewsItemComponent from '../components/NewsItem';
import { Arrow } from '../images/arrow';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { theme } from '../constants/theme';
import 'moment/locale/es';
import 'moment/locale/pt';

export const query = graphql`
  query NewsDetail($uid: String, $lang: String) {
    prismic {
      recirculation: allNewss(sortBy: meta_firstPublicationDate_ASC, first: 4, lang: $lang){
        edges {
          node {
            _meta {
              id
              uid
              lang
              type
            }
            listing_image
            date
            title
          }
        }
      }
      allNewss(uid: $uid, lang: $lang) {
        edges {
          node {
            title
            listing_image
            date
            _meta {
              lang
              uid
              type
            }
            body1 {
            ... on PRISMIC_NewsBody1Single_image {
              type
              label
              primary {
                image
              }
            }
            ... on PRISMIC_NewsBody1Double_image {
              type
              label
              primary {
                image_left
                image_right
              }
            }
            ... on PRISMIC_NewsBody1Full_width_image {
              type
              label
              primary {
                image
              }
            }
            ... on PRISMIC_NewsBody1Image___text {
              type
              label
              primary {
                content
                image
              }
            }
          }
        }
      }
    }
  }
}
`;

const NewsDetail = ({ data, location }) => {
  const newsItem = data.prismic.allNewss.edges.slice(0, 1).pop();
  const recirculation = data.prismic.recirculation.edges;

  if (!newsItem || !newsItem.node) {
    return null;
  }

  // eslint-disable-next-line max-len
  const filteredRecirculation = recirculation.filter((news) => news.node._meta.uid !== newsItem.node._meta.uid);

  const momentFormatString = 'D MMMM YYYY';
  const { lang } = newsItem.node._meta;

  let moreHeader = 'Mas noticias';
  let viewAll = 'Ver todos los proyectos';

  if (lang === 'es-mx') {
    moreHeader = 'Mas noticias';
    viewAll = 'Ver todas las novedades';
    moment.locale('es');
  } else if (lang === 'pr-br') {
    moreHeader = 'Mais notícias';
    viewAll = 'Veja todas as notícias';
    moment.locale('pt');
  } else {
    moreHeader = 'More News';
    viewAll = 'See all news';
    moment.locale('en');
  }

  return (
    <Layout lang="es-mx" location={location}>
      <SEO title="Home" />
      <Date>{moment(newsItem.node.date).format(momentFormatString)}</Date>
      <Title>{newsItem.node.title}</Title>
      <NewsContent>
        { newsItem.node.body1 && (
          <ContentBody body={newsItem.node.body1} />
        )}
      </NewsContent>
      <NewsRecirculation>
        <SectionHeader>
          <h3>{moreHeader}</h3>
          <Link to={linkResolver({ type: 'news_landing', lang })}>
            <ViewAllText>{viewAll}</ViewAllText>
            <Arrow />
          </Link>
        </SectionHeader>
        <NewsList>
          {filteredRecirculation.map((n) => <NewsItemComponent newsItem={n} />)}
        </NewsList>
        <MobileLearnMore>
          <Link to={linkResolver({ type: 'news_landing', lang })}>
            <ViewAllText>{viewAll}</ViewAllText>
            <Arrow />
          </Link>
        </MobileLearnMore>
      </NewsRecirculation>
    </Layout>
  );
};

const NewsList = styled.ul`
  list-style: none;
  display: flex;
  padding: 0;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  justify-content: space-between;
  margin: 0;
`;

const NewsRecirculation = styled.div`
  margin: 140px 0px 0;
  padding: 0 40px;

  @media ${theme.breakpoints.size.l} {
    padding: 0 20px;
  }

  @media ${theme.breakpoints.size.s} {
    margin: 8px 0px 0;
  }

  ul {
    margin-bottom: 80px;

    @media ${theme.breakpoints.size.xl} {
      margin-bottom: 38px;
    }

    @media ${theme.breakpoints.size.s} {
      margin-bottom: 0;
    }



    li:last-child{
      margin-bottom: 58px;
    }
  }
`;

const MobileLearnMore = styled.div`
  align-items: center;
  justify-content: center;
  display: none;
  margin-bottom: 61px;
  @media ${theme.breakpoints.size.s} {
    display: flex;
  }

  a {
    display: flex;
    align-items: center;
    color: ${theme.colors.red};
    font-size: 18px;
    line-height: 38px;
    text-decoration: none;

    &:visited {
      color: ${theme.colors.red};
    }
  }
`;

const ViewAllText = styled.p`
  margin: 0 10px;
`;

const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  color: ${theme.colors.red};
  margin: 0 40px 20px  40px;

  @media ${theme.breakpoints.size.m} {
    margin: 0px 40px 20px 40px;
  }
  @media ${theme.breakpoints.size.s} {
    justify-content: center;
    margin: 0px 20px 20px 20px;
  }

  h3 {
    margin: 0 0;
    text-transform: uppercase;
    font-size: 30px;
    line-height: 36px;
    flex: 1;
    @media ${theme.breakpoints.size.s} {
      text-align: center;
    }
  }
  a {
    display: flex;
    align-items: center;
    color: ${theme.colors.red};
    font-size: 18px;
    line-height: 38px;
    text-decoration: none;
    @media ${theme.breakpoints.size.s} {
      display: none;
    }

    &:visited {
      color: ${theme.colors.red};
    }
  }
`;

const NewsContent = styled.div`
  margin: 0 80px;
  display: flex;

  @media ${theme.breakpoints.size.l} {
    margin: 0px 40px;
  }
  @media ${theme.breakpoints.size.s} {
    margin: 0px 20px;
  }
`;

const Date = styled.div`
  text-align: center;
  margin:6px 0 0;
  color: #979797;

  @media ${theme.breakpoints.size.xl} {
    margin:10px 0 0;
  }

  @media ${theme.breakpoints.size.l} {
    margin:20px 0 0;
  }

  @media ${theme.breakpoints.size.s} {
    margin: 30px 0 0;
  }
`;

const Title = styled.div`
  width: 60.2%;
  font-weight: normal;
  font-size: 60px;
  line-height: 72px;
  text-align: center;
  margin: 2px auto 65px;

  @media ${theme.breakpoints.size.xl} {
    margin: 15px auto 65px;
    width: 73.75%;
    padding: 0 40px;
    font-size: 48px;
    line-height: 56px;
  }

  @media ${theme.breakpoints.size.l} {
    padding: 0;
    font-size: 48px;
    margin: 15px auto 65px;
    line-height: 56px;

  }
  @media ${theme.breakpoints.size.m} {
    font-size: 38px;
    line-height: 45px;
    margin: 17px auto 65px;
  }
  @media ${theme.breakpoints.size.s} {
    font-size: 28px;
    line-height: 35px;
    margin: 10px auto 42px;
  }
`;

export default NewsDetail;
